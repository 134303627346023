import React from "react";
import Products from "../components/Products";
import Banner from "./Banner";

export default function Home() {
    return (
        <>
         <Banner />
         <Products />
        </>
    )
}